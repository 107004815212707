<!--
목적 : 도급업체 컴포넌트
-->
<template>
  <div class="fix-height">
    <q-field
      ref="customVendor"
      class="customVendor"
      :dense="dense"
      stack-label
      filled
      color="orange-7"
      :label="convertLabel"
      :disable="disabled||!editable"
      :rules="!required ? null : [val => !!val || '']"
      v-model="valueText"
      >
      <template v-slot:label v-if="label">
        <div class="row items-center all-pointer-events">
          <b>
            <i v-if="!required" class="pe-7s-note labelfrontIcon searchAreaLabelIcon"></i>
            <font class="formLabelTitle">{{convertLabel}}</font>
            <i v-if="required" class="material-icons labelfrontIcon text-requiredColor">check</i>
          </b>
        </div>
      </template>
      <template v-slot:control>
        {{valueText}}
      </template>
    </q-field>
  </div>
</template>

<script>
import mixinCommon from './js/mixin-common'
export default {
  name: 'c-vendor',
  mixins: [mixinCommon],
  props: {
    name: {
      type: String,
      default: 'field',
    },
    // TODO : 부모의 v-model의 값을 받아오는 속성
    value: { // 사용자의 id를 받아 처리
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'vendor',
    },
    placeholder: {
      type: String,
      default: '',
    },
    // 필수 입력 여부
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      vValue: null,
      valueText: '',
    };
  },
  computed: {
  },
  watch: {
    // todo : 부모의 v-model 변경을 감시
    value: {
      handler: function () {
        this.vValue = this.value;
        this.setValueText();
      },
      deep: true,
    },
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeMount() {
    this.init();
  },
  mounted() {
  },
  beforeDestroy() {
  },
  /* methods */
  methods: {
    init() {
      if (this.value) {
        this.vValue = this.value;
      }
      this.setValueText();
    },
    /**
     * v-model로 받은 값을 통해 사용자 정보 조회
     *
     * type에 따라 valueText에 표시되는 값 변경
     */
    setValueText() {
      this.valueText = this.$store.getters.user.userName;
      // if (this.vValue) {
      //   let list = this.$store.state.systemInfo.vendors;
      //   let vendor = this.$_.find(list, { vendorCd: this.vValue });
      //   if (vendor !== undefined) {
      //     this.valueText = vendor.vendorName;
      //   } else {
      //     this.valueText = '';
      //   }
      // } else {
      //   this.valueText = '';
      // }
    },
  },
};
</script>
<style lang="sass">
.customVendor
  .q-field__native.row
    padding-left: 3px
    font-size: 0.95em
    padding-top: 4px !important
  .q-field__native
    padding-bottom: 6px !important
  .q-field__label
    margin-left: 5px
  .material-icons
    font-size: 0.8rem
  .dateCloseIcon
    font-size: 0.8rem !important
    display: none
.customVendor:hover
  .dateCloseIcon
    display: block
.customVendor.q-field--dense .q-field__control, .customVendor.q-field--dense .q-field__marginal
  height: 33px !important
  min-height: 33px !important
.customVendor.q-field--filled .q-field__control
  border: 1px solid rgba(0,0,0,0.15) !important
  padding: 0px 8px 0px 2px
</style>