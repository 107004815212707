import Vue from 'vue'
import Quasar from 'quasar';
import router from './router'
import './navigationGard'

import BootstrapVue from "bootstrap-vue"
import VueSignaturePad from 'vue-signature-pad';
import {
  Cascader,
  CascaderPanel,
  Empty,
} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import panZoom from 'vue-panzoom'

import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ko';

import 'material-icons/iconfont/material-icons.css';

import App from './App'

/** 레이아웃 */
import Default from './Layout/Wrappers/baseLayout.vue';
import Pages from './Layout/Wrappers/pagesLayout.vue';
import Apps from './Layout/Wrappers/appLayout.vue';
import None from './Layout/Wrappers/noneLayout.vue';
/** 레이아웃 끝 */


/** #################### 2021-06-15 kdh 추가 #################### */
/** 상태관리 */
import store from './store';
import tableResizable from './components/js/table-resize';
/** 상태관리 끝 */

/** 라이브러리 추가 */
import lodash from 'lodash'; // A modern JavaScript utility library delivering modularity, performance & extras
import format from 'string-format';
import moment from 'moment';
import Cookies from 'js-cookie';
/** 라이브러리 추가 끝 */

/** js 폴더 영역 */
import httpRequest from '@/js/http'; // Promise based HTTP client for the browser and node.js
import backendConfig from '@/js/backendConfig.js';
import comm from '@/js/common.js';
import message from '@/js/message.js';
import constants from "@/js/const.ts";
/** js 폴더 영역 끝 */

/** 유틸 영역 */
import * as filter from '@/utils/filter';

Object.keys(filter).forEach(key => {
  Vue.filter(key, filter[key]);
});
/** 유틸 영역 끝 */


/** prop 설정 */
Vue.prototype.$_ = lodash;
Vue.prototype.$format = format;
Vue.prototype.$http = httpRequest;
Vue.prototype.$backend = backendConfig;
Vue.prototype.$moment = moment;
Vue.prototype.$comm = comm;
Vue.prototype.$commMessage = message;
Vue.prototype.$const = constants;
Vue.prototype.$cookies = Cookies;
Vue.prototype.$langJsonApi = "/json/lang/";
/** prop 설정 끝 */

/** config 설정 */
Vue.config.productionTip = false;
/** config 설정 끝 */

/** 컴포넌트 */
import ScrollContainer from '@/components/scrollContainer';
import CTable from '@/components/CTable';
import CTreeTable from '@/components/CTreeTable';
import CSearchBox from '@/components/CSearchBox';
import CText from '@/components/CText';
import CTextString from '@/components/CTextString';
import CTextNumber from '@/components/CTextNumber';
import CLabelText from '@/components/CLabelText';
import CField from '@/components/CField';
import CTextarea from '@/components/CTextarea';
import CSelect from '@/components/CSelect';
import CMultiSelect from '@/components/CMultiSelect';
import CPlant from '@/components/CPlant';
import CDept from '@/components/CDept';
import CDeptMulti from '@/components/CDeptMulti';
import CVendor from '@/components/CVendor';
import CVendorOther from '@/components/CVendorOther';
import CProcess from '@/components/CProcess';
import CMaterial from '@/components/CMaterial';
import CEquip from '@/components/CEquip';
import CDatepicker from '@/components/CDatepicker';
import CDialog from '@/components/CDialog';
import CStep from '@/components/CStep';
import CBtn from '@/components/CBtn';
import CTab from '@/components/CTab';
import CCard from '@/components/CCard';
import CCheckbox from '@/components/CCheckbox';
import CRadio from '@/components/CRadio';
import CUpload from '@/components/CUpload';
import CUploadPicture from '@/components/CUploadPicture';
import CUploadList from '@/components/CUploadList';
import CUploadColumn from '@/components/CUploadColumn';
import CExcelUpload from '@/components/CExcelUpload';
import CSidebarMenu from '@/components/CSidebarMenu';
import CTag from '@/components/CTag';
import CEquipClass from '@/components/CEquipClass';
import CPickFile from '@/components/CPickFile';
// import CApprBtn from '@/components/CApprBtn';
import CTextColumn from '@/components/CTextColumn';
import CTextareaColumn from '@/components/CTextareaColumn';
import CNumberColumn from '@/components/CNumberColumn';
// import CFacility from '@/components/CFacility';
import CChemMaterial from '@/components/CChemMaterial';
import CMultiField from '@/components/CMultiField';
import CAssessTooltip from '@/components/CAssessTooltip';
import CUpdateBtn from '@/components/CUpdateBtn';
import CWinPop from '@/components/CWinPop';
import CMapMarker from '@/components/CMapMarker';
import CConstrunction from '@/components/CConstrunction';
import { AppFullscreen, Loading, Notify } from 'quasar'

Vue.use(BootstrapVue);
Vue.use(VueSignaturePad);
Vue.use(Quasar, {
  plugins: {
    AppFullscreen,
    Loading,
    Notify
  },
});
Vue.use(Cascader);
Vue.use(CascaderPanel);
Vue.use(Empty);
Vue.use(panZoom);

Vue.component('default-layout', Default);
Vue.component('userpages-layout', Pages);
Vue.component('apps-layout', Apps);
Vue.component('non-layout', None);

Vue.component(ScrollContainer.name, ScrollContainer);
Vue.component(CTable.name, CTable);
Vue.component(CTreeTable.name, CTreeTable);
Vue.component(CSearchBox.name, CSearchBox);
Vue.component(CText.name, CText);
Vue.component(CTextString.name, CTextString);
Vue.component(CTextNumber.name, CTextNumber);
Vue.component(CLabelText.name, CLabelText);
Vue.component(CField.name, CField);
Vue.component(CTextarea.name, CTextarea);
Vue.component(CSelect.name, CSelect);
Vue.component(CMultiSelect.name, CMultiSelect);
Vue.component(CPlant.name, CPlant);
Vue.component(CDept.name, CDept);
Vue.component(CDeptMulti.name, CDeptMulti);
Vue.component(CVendor.name, CVendor);
Vue.component(CVendorOther.name, CVendorOther);
Vue.component(CProcess.name, CProcess);
Vue.component(CMaterial.name, CMaterial);
Vue.component(CEquip.name, CEquip);
Vue.component(CDatepicker.name, CDatepicker);
Vue.component(CDialog.name, CDialog);
Vue.component(CStep.name, CStep);
Vue.component(CBtn.name, CBtn);
Vue.component(CTab.name, CTab);
Vue.component(CCard.name, CCard);
Vue.component(CCheckbox.name, CCheckbox);
Vue.component(CRadio.name, CRadio);
Vue.component(CUploadList.name, CUploadList);
Vue.component(CUpload.name, CUpload);
Vue.component(CUploadPicture.name, CUploadPicture);
Vue.component(CUploadColumn.name, CUploadColumn);
Vue.component(CExcelUpload.name, CExcelUpload);
Vue.component(CSidebarMenu.name, CSidebarMenu);
Vue.component(CTag.name, CTag);
Vue.component(CEquipClass.name, CEquipClass);
Vue.component(CPickFile.name, CPickFile);
// Vue.component(CApprBtn.name, CApprBtn);
Vue.component(CTextColumn.name, CTextColumn);
Vue.component(CTextareaColumn.name, CTextareaColumn);
Vue.component(CNumberColumn.name, CNumberColumn);
// Vue.component(CFacility.name, CFacility);
Vue.component(CChemMaterial.name, CChemMaterial);
Vue.component(CMultiField.name, CMultiField);
Vue.component(CAssessTooltip.name, CAssessTooltip);
Vue.component(CUpdateBtn.name, CUpdateBtn);
Vue.component(CMapMarker.name, CMapMarker);
Vue.component(CConstrunction.name, CConstrunction);
Vue.component(CWinPop.name, CWinPop);
/** 컴포넌트 끝 */

Vue.directive('table-resizable', tableResizable)

new Vue({
  el: '#app',
  router,
  store,
  Quasar,
  template: '<App/>',
  components: { App }
});
